import api from './api';

const fetchIntegrations = async ({
  commands = null,
  category = null,
  nameSrm = null,
  page = null,
  limit = null,
  audience = null,
  events = null,
  serviceNetwork = null,
  trigger = null,
  published = null,
  active = null,
  cacheEnabled = null,
} = {}) => {
  try {
    const requestParams = {
      'filter[name_locations_name_srm_cont]': nameSrm,
      'filter[category_in]': category,
      'filter[audience_array_cont]': audience,
      'filter[events_array_cont]': events,
      'filter[service_network_array_cont]': serviceNetwork,
      'filter[trigger_type_array_cont]': trigger,
      'filter[commands_flow_array_cont]': commands,
      'filter[published_eq]': published,
      'filter[enabled_eq]': active,
      'filter[cache_enabled_eq]': cacheEnabled,
      'page[number]': page,
      'page[size]': limit,
    };

    // Remove keys with null values
    const filteredRequestParams = Object.fromEntries(
      Object.entries(requestParams).filter(([_key, value]) => value !== null),
    );

    const response = await api.get(`/integrations`, {
      params: filteredRequestParams,
    });

    const { data, meta } = response.data;

    return { data, totalRecords: meta?.total_records };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchArchivedIntegrations = async (page, limit, archived = true) => {
  try {
    const response = await api.get(`/integrations`, {
      params: {
        'page[number]': page,
        'page[size]': limit,
        archived,
      },
    });

    const { data, meta } = response.data;

    return { data, totalRecords: meta.total_records };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchIntegrationByUuid = async (uuid) => {
  try {
    const { data } = await api.get(
      `/integrations/${uuid}?include=integration_step_functions`,
    );

    return { data: data.data, included: data.included };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchIntegrationChangeHistory = async (uuid) => {
  try {
    const { data } = await api.get(`/integrations/${uuid}/change-history`);

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const updateIntegrationStaticAttributes = async (
  uuid,
  params,
  integrationMetadata,
  integrationCacheEnabled,
  integrationCacheTtl,
) => {
  try {
    const { data } = await api.put(
      `${process.env.REACT_APP_BASE_URL}/integrations/${uuid}`,
      {
        data: {
          type: 'integrations',
          attributes: {
            global_settings_attributes: Object.values(params),
            metadata: integrationMetadata,
            cache_enabled: integrationCacheEnabled,
            cache_ttl: integrationCacheTtl,
          },
        },
      },
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const updateIntegration = async (uuid, integrationAttributes) => {
  try {
    const { data } = await api.put(
      `${process.env.REACT_APP_BASE_URL}/integrations/${uuid}`,
      {
        data: {
          type: 'integrations',
          attributes: integrationAttributes,
        },
      },
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const archiveIntegration = async (uuid) => {
  try {
    const response = await api.delete(`/integrations/${uuid}`, { data: {} });

    return response;
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const toggleConfiguredIntegration = async (uuid, enabled) => {
  try {
    const { data } = await api.put(
      `${process.env.REACT_APP_BASE_URL}/configured-integrations/${uuid}`,
      {
        data: {
          type: 'configured_integrations',
          attributes: {
            enabled,
          },
        },
      },
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const toggleIntegration = async (uuid, value, integrationField) => {
  try {
    const params = {
      data: {
        type: 'integrations',
        attributes: {},
      },
    };
    params.data.attributes[integrationField] = value;
    const { data } = await api.put(
      `${process.env.REACT_APP_BASE_URL}/integrations/${uuid}`,
      params,
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const addIntegrationToLocation = async (attributes) => {
  try {
    const { data } = await api.post(
      `${process.env.REACT_APP_BASE_URL}/configured-integrations`,
      {
        data: {
          type: 'configured_integrations',
          attributes,
        },
      },
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const addIntegrationToMultipleLocations = async (
  integrationUuid,
  encodedCsvFile,
) => {
  try {
    const { data } = await api.post(
      `${process.env.REACT_APP_BASE_URL}/configured-integrations/bulk-create`,
      {
        data: {
          type: 'configured_integrations',
          attributes: {
            integration_uuid: integrationUuid,
            encoded_csv_file: encodedCsvFile,
          },
        },
      },
    );

    return { data: data.data, links: data.links };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const createIntegration = async (integrationAttributes) => {
  try {
    const { data } = await api.post(
      `${process.env.REACT_APP_BASE_URL}/integrations`,
      {
        data: {
          type: 'integrations',
          attributes: integrationAttributes,
        },
      },
    );

    return { data: data.data };
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

const fetchTemplateFile = async (uuid) => {
  try {
    const data = await api.get(`/integrations/${uuid}/bulk-template`, {
      responseType: 'blob',
    });

    return data;
  } catch (e) {
    return { errors: e.response?.data?.errors };
  }
};

export default {
  fetchIntegrations,
  fetchArchivedIntegrations,
  fetchIntegrationChangeHistory,
  addIntegrationToLocation,
  addIntegrationToMultipleLocations,
  fetchIntegrationByUuid,
  toggleConfiguredIntegration,
  toggleIntegration,
  updateIntegrationStaticAttributes,
  updateIntegration,
  createIntegration,
  archiveIntegration,
  fetchTemplateFile,
};
